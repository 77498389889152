"use client";

import { useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useRouter } from "next/navigation";
import {
  Badge,
  EmptyState,
  // InfiniteScroll,
  NextPrevType,
  Pagination,
  PaginationShape,
  ResourceQuantity,
  RowType,
  SectionHeading,
  Status,
  Table,
  TableCellProps,
  TextInput,
  Tooltip,
} from "@spheron/ui-library";
import SearchIcon from "@spheron/ui-library/dist/assets/search.svg";
import CopyIcon from "@spheron/ui-library/dist/assets/copy.svg";
import {
  convertEarningsToDisplay,
  copyToClipboard,
  getOrdinalSuffix,
  getProviderStatusFromStatusId,
} from "@/utils";
import LeaderboardCard from "@/components/cards/leaderboard-card";
import TableTokenCard from "@/components/cards/table-token-card";
import {
  getGpuIcon,
  getIcon,
  getPodiumIcon,
  getRegionIcon,
  leaderboardTableHeaders,
} from "@/utils/leaderboard";
import { getAllProviders } from "@/services/provider";
import { StatusResponse } from "@/services/status";
import makeBlockie from "ethereum-blockies-base64";
import Image from "next/image";
import LoadMore from "@/components/load-more/load-more";
import TableLoader from "@/components/loaders/table-loader";
import LeaderboardCardLoader from "@/components/loaders/leaderboard-card-loader";

interface Provider {
  id: string;
  name: string;
  region: string;
  address: string;
  hostUri: string;
  status: string;
  trust: number;
  specs: StatusResponse;
  perEraRewardData: number;
  totalRewardsData: number;
  timestamp: number;
  version: string;
  rank: number;
}

interface ProviderResponse {
  providers: Provider[];
  totalCount: number;
  pageSize: number;
  pageNumber: number;
  totalPages: number;
}

export default function IndexPage() {
  const [allProviders, setAllProviders] = useState<Provider[]>([]);
  const [displayedProviders, setDisplayedProviders] = useState<Provider[]>([]);
  const [topProviders, setTopProviders] = useState<Provider[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [providerLoading, setProviderLoading] = useState(true);
  const [copyText, setCopyText] = useState("");
  const pageSize = 8;

  useEffect(() => {
    const fetchAllProviders = async () => {
      setProviderLoading(true);
      try {
        const data = await getAllProviders();
        const sortedProviders = data.providers;
        setAllProviders(sortedProviders);
        setTopProviders(sortedProviders.slice(0, 3));
        setDisplayedProviders(sortedProviders.slice(0, pageSize));
      } catch (error) {
        console.error("Failed to fetch providers:", error);
      } finally {
        setProviderLoading(false);
      }
    };

    fetchAllProviders();
  }, []);

  const filteredProviders = useMemo(() => {
    return allProviders.filter(
      (provider) =>
        provider.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        provider.address.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [allProviders, searchTerm]);

  useEffect(() => {
    setDisplayedProviders(filteredProviders.slice(0, pageSize));
  }, [filteredProviders]);

  // const loadMoreProviders = () => {
  //   setProviderLoading(true);
  //   setTimeout(() => {
  //     const currentLength = displayedProviders.length;
  //     const more = filteredProviders.slice(
  //       currentLength,
  //       currentLength + pageSize
  //     );
  //     setDisplayedProviders((prev) => [...prev, ...more]);
  //     setProviderLoading(false);
  //   }, 800);
  // };

  const router = useRouter();

  const tableRow: {
    id: number;
    tableRowType: RowType;
    tableRow: TableCellProps[];
    handleRowClick?: () => void;
  }[] =
    displayedProviders.map((provider, i) => {
      return {
        id: i,
        tableRowType: "record",
        handleRowClick() {
          router.push(`/${provider.address}`, { scroll: false });
          window.scrollTo(0, 0);
        },
        tableRow: [
          {
            id: 0,
            tableCellType: "default",
            content: (
              <div className="flex flex-row items-center justify-center gap-2 h-[84px]">
                {getPodiumIcon(provider.rank)}
                <span
                  className="text-base-para-text-color dark:text-dark-base-para-text-color
              text-[14px] font-400 leading-5 flex items-center"
                >
                  {getOrdinalSuffix(provider.rank)}
                </span>
              </div>
            ),
          },
          {
            id: 1,
            tableCellType: "default",
            content: (
              <TableTokenCard
                cellIcon={
                  <Image
                    width={24}
                    height={24}
                    src={makeBlockie(provider.address)}
                    alt=""
                    priority
                    className="rounded-full"
                  />
                }
                titleText={provider.name}
                classname="justify-center"
              />
            ),
          },
          {
            id: 1.1,
            tableCellType: "default",
            content: (
              <>
                {provider.version !== "-" ? (
                  <Badge
                    badgeType="default"
                    solid={false}
                    isBold
                    size="large"
                    text={provider.version}
                  />
                ) : (
                  <span className="text-sm font-semibold">
                    {provider.version}
                  </span>
                )}
              </>
            ),
          },
          {
            id: 2,
            tableCellType: "default",
            content: (
              <div className="uppercase">
                <Badge
                  badgeType="default"
                  solid={false}
                  size="large"
                  leftIcon={getRegionIcon(provider.region)}
                  text={provider.region}
                />
              </div>
            ),
          },
          {
            id: 3,
            tableCellType: "default",
            content: (
              <Status
                state={getProviderStatusFromStatusId(Number(provider.status))}
                tooltipText={`This provider is in ${getProviderStatusFromStatusId(
                  Number(provider.status)
                )} state`}
              />
            ),
          },
          {
            id: 4,
            tableCellType: "default",
            content: (
              <TableTokenCard
                cellIcon={
                  <Image
                    src={`/tiers/tier-${(11 - provider.trust).toString()}.webp`}
                    alt="Tier-master"
                    height={36}
                    width={36}
                  />
                }
                titleText={`Tier ${provider.trust}`}
                classname="justify-center w-max"
              />
            ),
          },
          {
            id: 5,
            tableCellType: "default",
            content: (
              <TableTokenCard
                titleText={`${convertEarningsToDisplay(
                  provider.perEraRewardData.toString()
                ).toFixed(2)} SP`}
                classname="justify-center w-max"
              />
            ),
          },
          {
            id: 6,
            tableCellType: "default",
            content: (
              <TableTokenCard
                titleText={`${convertEarningsToDisplay(
                  provider.totalRewardsData.toString()
                ).toFixed(2)} SP`}
                classname="justify-center w-max"
              />
            ),
          },
          {
            id: 7,
            tableCellType: "default",
            content: (
              <div className="flex flex-col gap-1 w-max py-1">
                <div className="flex flex-row gap-2">
                  <div className="flex flex-row gap-x-2">
                    {provider.specs.gpuInfos.slice(0, 2).map((resource) => (
                      <ResourceQuantity
                        key={`${resource.vendor}|${resource.name}`}
                        resource={resource.name}
                        quantifier={resource.num}
                        icon={getGpuIcon(resource.vendor)}
                        data-case="normal"
                      />
                    ))}
                  </div>
                  {provider.specs.gpuInfos.length > 2 && (
                    <span className="text-base-heading-text-color dark:text-dark-base-heading-text-color text-[11px] leading-3 flex items-center">
                      +{provider.specs.gpuInfos.length - 2} More...
                    </span>
                  )}
                  {provider.specs.gpuInfos.length > 0 && (
                    <Tooltip
                      text={copyText ? copyText : "Copy GPU Code"}
                      position="top"
                      type="float"
                    >
                      <button
                        className="h-min mt-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCopyText("Copied");

                          setTimeout(() => {
                            setCopyText("Copy GPU Code");
                          }, 500);

                          const gpuName = provider?.specs?.gpuInfos?.slice(
                            0,
                            2
                          )[0]?.name;

                          if (gpuName) {
                            copyToClipboard(gpuName);
                          }
                        }}
                      >
                        <CopyIcon className="text-base-icon dark:text-dark-base-icon h-4 w-4 cursor-pointer hover:text-base-para-text-color dark:hover:text-dark-base-para-text-color" />
                      </button>
                    </Tooltip>
                  )}
                </div>

                {/* <div className="flex flex-row gap-2 justify-between w-max"> */}
                <div
                  className="flex flex-row gap-x-1 items-center justify-center w-max
                  text-base-sub-text-color dark:text-dark-base-sub-text-color text-[11px]"
                >
                  <div className="flex items-center justify-center font-normal gap-x-0.5">
                    {getIcon("cpu")}
                    CPU
                  </div>
                  <p className="font-bold text-center text-base-para-text-color dark:text-dark-base-para-text-color leading-3">
                    {provider.specs.totalCPUs} CPUs
                  </p>
                </div>
                <div
                  className="flex flex-row gap-x-1 items-center justify-center w-max
                  text-base-sub-text-color dark:text-dark-base-sub-text-color text-[11px]"
                >
                  <div className="flex items-center justify-center font-normal gap-x-0.5">
                    {getIcon("ram")}
                    RAM
                  </div>
                  <p className="font-bold text-center text-base-para-text-color dark:text-dark-base-para-text-color leading-3">
                    {provider.specs.totalMemory} GB
                  </p>
                </div>
                <div
                  className="flex flex-row gap-x-1 items-center justify-center w-max
                  text-base-sub-text-color dark:text-dark-base-sub-text-color text-[11px]"
                >
                  <div className="flex items-center justify-center font-normal gap-x-0.5">
                    {getIcon("storage")}
                    STORAGE
                  </div>
                  <p className="font-bold text-center text-base-para-text-color dark:text-dark-base-para-text-color leading-3">
                    {provider.specs.totalStorage} GB
                  </p>
                </div>
                {/* </div> */}
              </div>
            ),
          },
        ],
      };
    }) || [];

  const leaderboardTop3 = useMemo(() => {
    if (topProviders.length > 2) {
      return [
        {
          titleText: topProviders[1].name,
          isVerified: Number(topProviders[1].status) === 2,
          icon: (
            <Image
              width={48}
              height={48}
              src={makeBlockie(topProviders[1].address)}
              alt=""
              priority
              className="rounded-full"
            />
          ),
          podiumPlace: 2,
          status: topProviders[1].status,
          timestamp: topProviders[1].timestamp,
        },
        {
          titleText: topProviders[0].name,
          isVerified: Number(topProviders[0].status) === 2,
          icon: (
            <Image
              width={48}
              height={48}
              src={makeBlockie(topProviders[0].address)}
              alt=""
              priority
              className="rounded-full"
            />
          ),
          podiumPlace: 1,
          status: topProviders[0].status,
          timestamp: topProviders[0].timestamp,
        },
        {
          titleText: topProviders[2].name,
          isVerified: Number(topProviders[2].status) === 2,
          icon: (
            <Image
              width={48}
              height={48}
              src={makeBlockie(topProviders[2].address)}
              alt=""
              priority
              className="rounded-full"
            />
          ),
          podiumPlace: 3,
          status: topProviders[1].status,
          timestamp: topProviders[1].timestamp,
        },
      ];
    }
    return [];
  }, [topProviders]);

  // if (isLoading) {
  //   return (
  //     <div className="w-full h-screen flex items-center justify-center">
  //       <div className="-mt-5 lg:-mt-20">
  //         <LineLoader />
  //       </div>
  //     </div>
  //   );
  // }

  const [currentPage, setCurrentPage] = useState<number>(1);

  const loadMoreProviders = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * pageSize;
    const selectedProviders = filteredProviders.slice(
      startIndex,
      startIndex + pageSize
    );
    setDisplayedProviders(selectedProviders);
  };

  return (
    <div className="mt-10 flex flex-col justify-center items-center gap-y-5">
      <div className="flex flex-row flex-wrap gap-4 justify-between w-full">
        <SectionHeading
          showRefresh={false}
          showSwitch={false}
          heading="Leaderboard"
          subHeading="Discover and Compare Top Providers in the Spheron Network"
          handleIconClick={() => {}}
          handleRefreshClick={() => {}}
          handleSwitchClick={() => {}}
          switchDisable={false}
          handleClick={() => {}}
          loading={false}
          time={""}
          refreshType={"default"}
          showText={false}
          toggleId={""}
          isChecked={false}
          handleChange={() => {}}
        />
        <div className="w-[287px]">
          {providerLoading && displayedProviders.length === 0 ? (
            <Skeleton height={40} width="100%" />
          ) : (
            <TextInput
              inputSize="compact"
              leftIcon={
                <SearchIcon
                  className="w-6 h-6 text-base-para-text-color
            dark:text-dark-base-para-text-color"
                />
              }
              placeholder="Search Provider"
              value={searchTerm}
              onChange={(value) => {
                setSearchTerm(value as string);
                setCurrentPage(1);
              }}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 w-full items-end">
        {providerLoading && displayedProviders.length === 0 ? (
          <>
            {Array.from(Array(3).keys()).map((i) => (
              <LeaderboardCardLoader key={i} podiumPlace={i} />
            ))}
          </>
        ) : (
          <>
            {leaderboardTop3?.map((content) => (
              <LeaderboardCard
                key={content.podiumPlace}
                titleText={content.titleText}
                isVerified={content.isVerified}
                podiumPlace={content.podiumPlace}
                status={content.status}
                timestamp={content.timestamp}
                icon={content.icon}
              />
            ))}
          </>
        )}
      </div>
      <div className="w-full overflow-x-auto lg:overflow-x-visible">
        {providerLoading && displayedProviders.length === 0 ? (
          <TableLoader
            headers={leaderboardTableHeaders.map(
              (header) => header.title || ""
            )}
            rows={6}
          />
        ) : (
          // <InfiniteScroll
          //   fetchFn={loadMoreProviders}
          //   loader={
          //     <div className="mt-4">
          //       <LoadMore loading handleClick={() => {}} />
          //     </div>
          //   }
          //   hasMoreItems={filteredProviders.length > displayedProviders.length}
          //   loading={providerLoading}
          // >
          <div className="flex flex-col gap-y-5 w-full">
            <Table
              tableHeader={{
                tableRowType: "record",
                tableRow: leaderboardTableHeaders,
              }}
              tableRows={tableRow}
              classname="leaderboard-table leaderboard-table-hover"
            />
          </div>
          // </InfiniteScroll>
        )}
        {displayedProviders.length === 0 && searchTerm && (
          <div className="h-72 empty-state">
            <EmptyState
              entityTitle="No Provider found for your search criteria"
              subText=""
              showButton={false}
              onClick={() => {}}
              buttonDisabled={false}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col lg:flex-row justify-between items-center mt-3 w-full">
        {!providerLoading ? (
          <div className="text-gray-400 text-sm">
            Page {currentPage} -{" "}
            {Math.ceil(filteredProviders.length / pageSize)}
          </div>
        ) : (
          <Skeleton
            width={120}
            height={20}
            containerClassName="flex"
            duration={2}
          />
        )}
        <div className="flex justify-center items-center">
          {!providerLoading ? (
            <>
              {filteredProviders.length > pageSize && (
                <Pagination
                  paginationList={filteredProviders.length}
                  numberPerPage={pageSize}
                  handlePrevClick={(i) => {
                    loadMoreProviders(i);
                    setCurrentPage(i);
                  }}
                  handleNextClick={(i) => {
                    loadMoreProviders(i);
                    setCurrentPage(i);
                  }}
                  handleClick={(i) => {
                    loadMoreProviders(i);
                    setCurrentPage(i);
                  }}
                  shape={PaginationShape.SQUARE}
                  actionType={NextPrevType.TEXT}
                />
              )}
            </>
          ) : (
            <>
              <Skeleton
                width={140}
                height={20}
                containerClassName="flex"
                duration={2}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
