import { ethers, JsonRpcProvider, Contract } from "ethers";
import { SAMPLE_PROVIDER_ADDRESS, SPHERON_RPC_URL } from "@/config";
import RewardsManagerAbi from "@/contracts/abis/ProviderRewardsManager.json";
import { ProviderRewardsManager as RewardManager } from "@/contracts/addresses";
import { getProviderAttrs, getProviderPendingAttributes } from "./details";
import { getResource } from "./resources";

export const getBaseRewardsPerEra = async (category: string) => {
  if (category === "CPU") {
    return 100000000000000000;
  } else {
    return 20000000000000000000;
  }
  // const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  // const contractAbi = RewardsManagerAbi;
  // const contractAddress = RewardManager;

  // const contract: Contract = new ethers.Contract(
  //   contractAddress,
  //   contractAbi,
  //   provider
  // );
  // const response = await contract.getProviderBaseRewardsPerEra(category);

  // return response;
};

export const getProviderTotalMultiplierPoints = async (
  providerAddress: string
) => {
  const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  const contractAbi = RewardsManagerAbi;
  const contractAddress = RewardManager;

  const contract: Contract = new ethers.Contract(
    contractAddress,
    contractAbi,
    provider
  );
  const response = await contract.getProviderTotalMultiplierPoints(
    providerAddress
  );
  const totalMultiplier = Number(response) / 10;

  console.log("total multiplier -> ", totalMultiplier);

  return totalMultiplier;
};

export const getTrustMultiplier = async (tier: string) => {
  const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  const contractAbi = RewardsManagerAbi;
  const contractAddress = RewardManager;

  const contract: Contract = new ethers.Contract(
    contractAddress,
    contractAbi,
    provider
  );
  const response = await contract.getRewardMultiplier(tier);
  const trustMuliplier = Number(response) / 10;

  console.log("trust tier multiplier -> ", trustMuliplier);

  return trustMuliplier;
};

export const getProviderRewardsPerEra = async (
  providerAddress: string,
  isRegistered: boolean,
  trustMultiplier: number
) => {
  if (isRegistered) {
    return 0;
  }
  const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  const contractAbi = RewardsManagerAbi;
  const contractAddress = RewardManager;

  const contract: Contract = new ethers.Contract(
    contractAddress,
    contractAbi,
    provider
  );
  const response = await contract.getProviderRewardsPerEra(providerAddress);

  const rewardsPerEra = Number(response);

  console.log("rewards per era -> ", rewardsPerEra, trustMultiplier);

  return rewardsPerEra;
};

export const getTotalRewards = async (
  providerAddress: string,
  isRegistered: boolean,
  perEraRewardData: number
) => {
  if (isRegistered) {
    return 0;
  }
  const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  const contractAbi = RewardsManagerAbi;
  const contractAddress = RewardManager;

  const contract: Contract = new ethers.Contract(
    contractAddress,
    contractAbi,
    provider
  );
  // const currentEra = await contract.getCurrentEra();
  // const currentProvider = await contract.providers(providerAddress);
  // console.log("current era -> ", currentEra);
  // console.log("last claimed era -> ", currentProvider.lastClaimedEra);
  // console.log("per era rewards -> ", perEraRewardData);
  // const eraElapsed = Number(currentEra - currentProvider.lastClaimedEra);
  // const currentRewards = BigInt(perEraRewardData * eraElapsed);
  const currentRewards = await contract.getAccRewardsForProvider(
    providerAddress
  );
  // const pendingRewards = await contract.pendingRewards(providerAddress);
  // const totalRewards =
  //   currentRewards /* unclaimed */ +
  //   pendingRewards; /* after reset attributes */
  const totalRewards = currentRewards;

  // console.log("currentRewards -> ", currentRewards);
  // console.log("pendingRewards -> ", pendingRewards);
  console.log("total rewards -> ", totalRewards);

  return totalRewards;
};

export const getProviderSlashedAmount = async (providerAddress: string) => {
  const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  const contractAbi = RewardsManagerAbi;
  const contractAddress = RewardManager;

  const contract: Contract = new ethers.Contract(
    contractAddress,
    contractAbi,
    provider
  );
  const response = await contract.providerSlashedAmount(providerAddress);

  console.log("slashed amount -> ", response);

  return response;
};

export const getCategories = async () => {
  const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  const contractAbi = RewardsManagerAbi;
  const contractAddress = RewardManager;

  const contract: Contract = new ethers.Contract(
    contractAddress,
    contractAbi,
    provider
  );
  const response = await contract.categories();

  console.log("categories -> ", response);

  return response;
};

export interface Resource {
  id: string;
  name: string;
  tier: string;
  multiplier: string;
  count: number;
}

export const getRewardsBreakdown = async (
  providerAddress: string,
  category: string
): Promise<Resource[] | null> => {
  try {
    const attributeIds: Array<Array<bigint>> = await getProviderAttrs(
      providerAddress,
      category
    );
    console.log(
      "Attribute IDs:",
      attributeIds.map((id) => id[0].toString())
    );

    const resources: Resource[] = await Promise.all(
      attributeIds.map(async ([id, count]) => {
        const [name, tier, multiplier]: [string, string, bigint] =
          await getResource(id.toString(), category);
        return {
          id: id.toString(),
          name,
          tier,
          multiplier: (Number(multiplier) / 100).toString(),
          count: Number(count),
        };
      })
    );

    console.log("Resources -> ", resources);
    return resources;
  } catch (error) {
    console.error("Error fetching rewards breakdown:", error);
    return null;
  }
};

export const getDeploymentTimestamp = async () => {
  // const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  // const contractAbi = RewardsManagerAbi;
  // const contractAddress = RewardManager;

  // const contract: Contract = new ethers.Contract(
  //   contractAddress,
  //   contractAbi,
  //   provider
  // );
  // const response = await contract.deploymentTimestamp();
  const response = 1739965046; // Rewards contract creator txn block timestamp
  const deploymentTimestamp = Number(response.toString()) * 1000;

  console.log("deployment time -> ", deploymentTimestamp);

  return deploymentTimestamp;
};

export const getPendingAttributes = async (
  providerAddress: string,
  category: string
) => {
  try {
    const pendingAttributeIds: Array<Array<bigint>> =
      await getProviderPendingAttributes(providerAddress, category);

    console.log(
      "Pending Attribute IDs:",
      pendingAttributeIds.map((id) => id[0].toString())
    );

    const resources: Resource[] = await Promise.all(
      pendingAttributeIds.map(async ([id, count]) => {
        const [name, tier, multiplier]: [string, string, bigint] =
          await getResource(id.toString(), category);
        return {
          id: id.toString(),
          name,
          tier,
          multiplier: (Number(multiplier) / 100).toString(),
          count: Number(count),
        };
      })
    );

    console.log("Pending Resources -> ", resources);
    return resources;
  } catch (error) {
    console.log("Error in fetching pending attrs", error);
    return null;
  }
};
